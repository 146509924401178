.card {
  width: 360px;
  box-shadow: 3px 8px 18px rgba(0,0,0,.18);
  border-radius: 15px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  //transition: background-color .2s;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .35s ease-in-out;
  &--open {
    height: 100px;
    opacity: 1;
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__text {
    display: flex;
    flex-direction: column;
    margin-top: -120px;
    opacity: 0;
    transition: all .3s ease-in-out;
    &--open {
      margin-top: 120px;
      opacity: 1;
    }
  }
  &__filter {
    display: flex;
    flex-direction: row;
    padding-top: 60px;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    &--open {
      opacity: 1;
    }
  }
  &__switcher {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
  }
  &__title {
    margin: 0 0 8px 0;
    font-size: 26px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px;
    white-space: nowrap;
  }
  &__subtitle {
    margin: 0px;
    font-size: 18px;
    font-weight: 400;
    color: #bebebe;
  }
}
