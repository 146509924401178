@import './variables/colors';
@import './base';
@import '../../index';
@import './theme';
@import './media';

main {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
