.textarea {
  width: 56vw;
  padding: 7% 2% 0;
  font-size: 6.2vw;
  line-height: 1;
  text-align: left;
  resize: none;
  border: none;
  outline: none;
  background: inherit;
  overflow: hidden;
  transition: opacity .25s ease-out;
  &--using {
    opacity: 0;
    width: 100%;
    text-align: center;
  }
  &--visible {
    opacity: 1
  }
  &::selection {
    background: #383b3f;
    color: #fafafa;
  }
}
